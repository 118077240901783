<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-card bg-variant="light">
      <b-card-group deck>
        <div v-for="(item) in collection_images" :key="item.id">
          <item-image-association-card
            :data_item="item"
            :mappings="items"
            :included="included"
          ></item-image-association-card>
        </div>
      </b-card-group>
    </b-card>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ItemImageAssociationCard from './ItemImageAssociationCard.vue'

export default {
  components: {
    VuexBreadcrumb,
    ItemImageAssociationCard
  },
  props: {
    id: {
      Type: String
    },
    collection_id: {
      Type: String
    }
  },
  data () {
    return {
      totalRows: 0,
      perPage: 0,
      items: [],
      included: [],
      collection_images: [],
    }
  },
  methods: {
    callApi () {
      http_v1.get (
        'api/admin/v1/item_image_associations', {
          params: {
            collection_ids: this.collection_id,
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.items = response.data.data
          this.included = response.data.included
          this.collection_images = []
          this.included.forEach(el => {
            if( el.type === 'collection-images') {
              this.collection_images.push(el)
            }
          })
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
  mounted() {
    this.callApi()
  }
}
</script>
